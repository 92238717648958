'use client'
import ReactGA from 'react-ga4'
ReactGA.initialize(process.env.gaKey)

export const ReactGAEvent = ({ action = null, event = null, label = null, category = null, value = 0 }) => {
	ReactGA.event({
		action,
		event,
		category,
		label, // Optional, a label to describe the action
		value, // Optional, any numeric value (e.g., purchase amount, etc.)
	})
}

export const ReactGASent = ({ hitType = null, page = null, title = null }) => {
	// Send pageview with a custom path
	ReactGA.send({ hitType, page, title })
}
