import React from "react";

function LinearGrediantLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={111}
      height={15}
      viewBox="0 0 111 15"
      fill="none"
    >
      <path
        d="M13.5668 1.20386H11.7309L8.12331 10.0339L4.64039 1.20386H2.79127L0.490723 14.3612H2.65339L4.09642 5.27231L7.68512 14.3612H8.50107L12.1937 5.28364L13.4894 14.3612H15.635L13.5668 1.20386Z"
        fill="url(#paint0_linear_552_3682)"
      />
      <path
        d="M26.2787 3.06998V1.20386H18.6196V14.3612H26.2787V12.495H20.7143V8.09793H26.1162V6.23182H20.7143V3.06998H26.2787Z"
        fill="url(#paint1_linear_552_3682)"
      />
      <path
        d="M27.5718 1.20386V3.06998H30.7619V14.3612H32.8566V3.06998H36.0373V1.20386H27.5718Z"
        fill="url(#paint2_linear_552_3682)"
      />
      <path
        d="M43.281 1.20386H41.5414L35.5615 14.3612H37.8167L39.3636 10.9103H45.3039L46.7601 14.3612H49.0512L43.281 1.20386ZM40.2344 8.89311L42.3914 4.05971L44.4502 8.89311H40.2344Z"
        fill="url(#paint3_linear_552_3682)"
      />
      <path
        d="M52.8798 12.495V1.20386H50.7852V14.3612H56.9596V12.495H52.8798Z"
        fill="url(#paint4_linear_552_3682)"
      />
      <path
        d="M65.7668 8.40203C65.2889 7.8524 64.6259 7.51242 63.7817 7.38776C64.422 7.23099 64.9263 6.908 65.2908 6.41692C65.6572 5.92583 65.8404 5.32897 65.8404 4.62823C65.8404 4.05215 65.7252 3.52707 65.4986 3.0511C65.27 2.57512 64.9376 2.17092 64.5013 1.84227C64.1896 1.60806 63.8119 1.44374 63.368 1.34741C62.9241 1.25108 62.2026 1.20386 61.2016 1.20386H58.9199V14.3612H61.2205C62.5199 14.3612 63.4398 14.2875 63.98 14.1421C64.5183 13.9947 64.9792 13.7416 65.3626 13.3809C65.7403 13.0333 66.0218 12.6216 66.2088 12.1418C66.3939 11.6621 66.4864 11.1143 66.4864 10.4967C66.4864 9.65241 66.2465 8.95356 65.7668 8.40203ZM60.1155 2.24647H61.6058C62.7277 2.24647 63.5078 2.43158 63.9479 2.80367C64.388 3.17576 64.6089 3.81417 64.6089 4.71701C64.6089 5.55752 64.3823 6.15627 63.929 6.51514C63.4776 6.87401 62.7032 7.05533 61.6058 7.05533H60.1155V2.24647ZM64.9489 11.9567C64.7827 12.3383 64.558 12.6367 64.2765 12.852C64.048 13.0258 63.7703 13.1486 63.4398 13.2203C63.1111 13.2921 62.5105 13.328 61.6436 13.328H60.1155V8.08849H61.9042C62.5558 8.08849 63.0375 8.11682 63.3453 8.17349C63.6551 8.23015 63.9214 8.32459 64.15 8.45681C64.4786 8.65513 64.7374 8.95166 64.9225 9.3502C65.1095 9.74873 65.202 10.2115 65.202 10.7404C65.202 11.171 65.117 11.5771 64.9489 11.9567Z"
        fill="url(#paint5_linear_552_3682)"
      />
      <path
        d="M82.0387 5.14713C81.6874 4.3104 81.1774 3.56054 80.505 2.89569C79.8459 2.24783 79.0922 1.75487 78.2442 1.41678C77.3961 1.07868 76.4857 0.908691 75.5149 0.908691C74.544 0.908691 73.645 1.07679 72.7988 1.4111C71.9526 1.74731 71.2009 2.24217 70.5417 2.89569C69.8693 3.56054 69.3593 4.3104 69.008 5.14713C68.6567 5.98198 68.4829 6.8697 68.4829 7.81032C68.4829 8.75093 68.6567 9.61977 69.008 10.4489C69.3593 11.2781 69.8693 12.0261 70.5417 12.6909C71.2122 13.3501 71.9734 13.8525 72.8215 14.1963C73.6695 14.5401 74.5667 14.7138 75.5149 14.7138C76.463 14.7138 77.3602 14.5401 78.2121 14.1963C79.0639 13.8525 79.8289 13.3501 80.505 12.6909C81.1718 12.0374 81.6799 11.2932 82.035 10.4584C82.3882 9.62167 82.5657 8.7396 82.5657 7.81032C82.5657 6.88103 82.39 5.98198 82.0387 5.14713ZM80.862 10.0523C80.5712 10.7625 80.1518 11.3971 79.6079 11.9543C79.0488 12.5058 78.4236 12.9251 77.7266 13.2122C77.0316 13.4993 76.2949 13.6429 75.5149 13.6429C74.7348 13.6429 73.9755 13.5012 73.2937 13.216C72.6099 12.9327 71.9923 12.5115 71.4408 11.9543C70.8892 11.3915 70.468 10.7568 70.1809 10.0523C69.8938 9.34968 69.7484 8.60172 69.7484 7.81032C69.7484 7.01891 69.8938 6.26718 70.1809 5.56833C70.468 4.87136 70.8892 4.23673 71.4408 3.66821C71.9979 3.11668 72.6212 2.69736 73.3106 2.41027C74.0001 2.12128 74.7348 1.97774 75.5149 1.97774C76.2949 1.97774 77.0429 2.12128 77.7399 2.41027C78.4387 2.69736 79.062 3.11668 79.6079 3.66821C80.1594 4.23107 80.5787 4.86381 80.8658 5.56833C81.1548 6.27284 81.2983 7.0208 81.2983 7.81032C81.2983 8.59983 81.1529 9.34401 80.862 10.0523Z"
        fill="url(#paint6_linear_552_3682)"
      />
      <path
        d="M98.1451 5.14713C97.7938 4.3104 97.2838 3.56054 96.6114 2.89569C95.9522 2.24783 95.1986 1.75487 94.3505 1.41678C93.5025 1.07868 92.5921 0.908691 91.6212 0.908691C90.6504 0.908691 89.7495 1.07679 88.9052 1.4111C88.059 1.74731 87.3073 2.24217 86.6481 2.89569C85.9757 3.56054 85.4657 4.3104 85.1144 5.14713C84.7631 5.98198 84.5874 6.8697 84.5874 7.81032C84.5874 8.75093 84.7631 9.61977 85.1144 10.4489C85.4657 11.2781 85.9757 12.0261 86.6481 12.6909C87.3186 13.3501 88.0798 13.8525 88.9278 14.1963C89.7759 14.5401 90.6731 14.7138 91.6212 14.7138C92.5694 14.7138 93.4666 14.5401 94.3184 14.1963C95.1703 13.8525 95.9352 13.3501 96.6114 12.6909C97.2782 12.0374 97.7863 11.2932 98.1413 10.4584C98.4945 9.62167 98.6721 8.7396 98.6721 7.81032C98.6721 6.88103 98.4964 5.98198 98.1451 5.14713ZM96.9684 10.0523C96.6775 10.7625 96.2582 11.3971 95.7142 11.9543C95.1552 12.5058 94.53 12.9251 93.833 13.2122C93.1379 13.4993 92.3994 13.6429 91.6212 13.6429C90.8431 13.6429 90.0819 13.5012 89.4 13.216C88.7163 12.9327 88.0987 12.5115 87.5471 11.9543C86.9956 11.3915 86.5744 10.7568 86.2873 10.0523C86.0002 9.34968 85.8548 8.60172 85.8548 7.81032C85.8548 7.01891 86.0002 6.26718 86.2873 5.56833C86.5744 4.87136 86.9956 4.23673 87.5471 3.66821C88.1043 3.11668 88.7276 2.69736 89.417 2.41027C90.1064 2.12128 90.8412 1.97774 91.6212 1.97774C92.4013 1.97774 93.1474 2.12128 93.8462 2.41027C94.5451 2.69736 95.1684 3.11668 95.7142 3.66821C96.2658 4.23107 96.6851 4.86381 96.9722 5.56833C97.2612 6.27284 97.4047 7.0208 97.4047 7.81032C97.4047 8.59983 97.2593 9.34401 96.9684 10.0523Z"
        fill="url(#paint7_linear_552_3682)"
      />
      <path
        d="M103.785 7.49541L110.022 1.20386H108.458L102.508 7.36886V1.20386H101.314V14.3612H102.508V7.68429L108.584 14.3612H110.318L103.785 7.49541Z"
        fill="url(#paint8_linear_552_3682)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_552_3682"
          x1="1.66408"
          y1="-7.43152"
          x2="1.66409"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_552_3682"
          x1="19.213"
          y1="-7.43152"
          x2="19.2131"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_552_3682"
          x1="28.2277"
          y1="-7.43152"
          x2="28.2277"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_552_3682"
          x1="36.6067"
          y1="-7.43152"
          x2="36.6067"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_552_3682"
          x1="51.2635"
          y1="-7.43152"
          x2="51.2636"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_552_3682"
          x1="59.5062"
          y1="-7.43152"
          x2="59.5062"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_552_3682"
          x1="69.574"
          y1="-8.15188"
          x2="69.574"
          y2="14.7429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_552_3682"
          x1="85.6787"
          y1="-8.15188"
          x2="85.6787"
          y2="14.7429"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_552_3682"
          x1="102.012"
          y1="-7.43152"
          x2="102.012"
          y2="14.3889"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6A200" />
          <stop offset={1} stopColor="#EA3162" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LinearGrediantLogo;
